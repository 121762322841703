  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/2-6-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-assure/'>Expertise d'assuré</a></li><li>Expertise contexte judiciaire</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>L’ assistance en Expertise judiciaire</h1>

<p>Dernier recours en cas de refus de garantie de votre assurance ou dans le cadre de litiges, l’expertise judiciaire fait intervenir un Expert judiciaire, nommé par le tribunal compétent. L’expert judiciaire est défini comme neutre, impartial et tenu de respecter le contradictoire.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expertise contexte judiciaire" />
</div>

<p>Il est missionné pour apporter des éléments techniques au Juge instruit pour l’affaire.</p>

<p>Que vous soyez la partie demanderesse ou la partie défenderesse, vous pouvez vite être dépassé par l’ampleur du contentieux et des échanges techniques avec l’Expert adverse.</p>

<p>Pour vous éviter un désavantage lors des différents échanges lors d’accédits, vous avez la possibilité d’être défendu techniquement par <strong>votre Expert</strong>.</p>
<p>Il vous accompagnera et vous défendra sur le plan technique et donnera tous les éléments nécessaires à votre avocat pour la défense de votre dossier devant le Juge.</p>

<p>Bien souvent, le rapport d’un Expert technique peut faire basculer la sentence d’un côté comme de l’autre !</p>

<p>Le choix d’un bon Expert est alors décisif !</p>

<h2>Pourquoi être accompagné par un expert indépendant d’assuré en expertise judiciaire ?</h2>

<p>Le cabinet RV EXPERTISES s’est spécialisé dans la défense des assurés lors d’expertise judiciaire. </p>

<p>Nous sommes épaulés par le conseil de sapiteurs, spécialistes en ingénierie structurelle ainsi que d’Avocats spécialisés en droit de la construction.</p>

<p>L’intervention de spécialistes, dans le traitement de dossier complexe aux enjeux financiers importants, peut se révéler déterminante dans la décision du juge qui s’appuie généralement sur le rapport de son Expert judiciaire.</p>


<p> </p>




                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-assure/expertise-judiciaire/' className='active'>Expertise contexte judiciaire</a></li><li><a href='/expertise-assure/expertise-judiciaire/assistance/' >Assistance expertise judicaire</a></li><li><a href='/expertise-assure/expertise-judiciaire/demande-avocat/' >Pour répondre aux besoins de l'avocat</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details